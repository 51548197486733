
import { codes } from './countryPhone.js';

const general = {
  init: function () {
    general.navbarDropdownProduct();
    general.breadcrumbProductDropdown();
    general.newsSlider();
    general.newsSliderTablet();
    general.companyMore();
    general.initStepsContact();
    general.initCountryPhones();
    general.countryPhones();
    general.newsListUnderlineEffect();
    general.newsListLoadMore();
    general.postCarousel();
    general.filterCateogry();
    general.navbarCollapseDropdown();
    general.backToTop();
    general.GoogleSearchBoxPlaceHolder();
    general.productDetailPreview();
    general.productDetailPreviewMobile();
    general.productDetailTableRow();
    general.productDetailRelated();
    general.btnSwitchingHover();
    general.searchMobileView();
    general.dropdownOurBusiness();
    general.productListContent();
    general.corporateLoadMore();
    general.searchGoogleNavbar();
    general.searchToogleDesktop()
    general.btnScrollToBottom();
    general.productListLoadMore();
    general.changeLanguage();
    general.categoryAnimation();
  },

  navbarDropdownProduct: function () {
    const elMainMenu = jQuery('#main-menu')
    if (elMainMenu[0]) {
      const navbarChild = [...elMainMenu[0].children]
      const terms = ["product", "produk", "製品"]
      const elProduct = navbarChild.filter(el => {
        const eltext = el.innerText.toLowerCase();
        return terms.some(term => eltext.includes(term))
      })
      if (elProduct[0].children[1]) {
        setTimeout(() => {
          const elProductChildren = elProduct[0].children[1];
          const elListMenu = elProductChildren.cloneNode(true);

          const elDiv = document.createElement('div');
          elDiv.classList.add("dropdown-menu")
          
          const navbarTitle = jQuery('.navbar-title')
          const elDivTitle = document.createElement('div');
          elDivTitle.append(navbarTitle[0].innerHTML);
          elDivTitle.classList.add("dropdown-title")

          elProduct[0].append(elDiv)
          elProduct[0].children[1].append(elDivTitle)

          if (navigator.userAgent.indexOf("Edg") !== -1) {
            elProduct[0].children[1].classList.add("edge-chromium");
          }

          elListMenu.classList.remove("dropdown-menu")
          elListMenu.classList.add("dropdown-list")
          elProduct[0].children[1].append(elListMenu)

          let isActive = false;
          elProduct[0].children[0].addEventListener("click", function () {
            elProduct[0].children[1].children[0].style.display = 'none';
            elProduct[0].children[1].children[1].style.display = 'none';
            elProduct[0].children[1].children[2].style.display = 'none';

            elProduct[0].children[1].style.opacity = "1";
            if (isActive) {
              elProduct[0].children[1].style.display = "none";
              isActive = false
            } else {
              elProduct[0].children[1].style.display = "block";
              isActive = true
            }
          })
          elProduct[0].children[0].addEventListener("focusout", function () {
            elProduct[0].children[1].style.opacity = "0";
            setTimeout(() => {
              elProduct[0].children[1].style.display = "none";
              isActive = false
            }, 250);
          })
        }, 500);
      }
    }
  },

  breadcrumbProductDropdown: function () {
    const elMainMenu = jQuery('#main-menu')
    if (elMainMenu[0]) {
      const navbarChild = [...elMainMenu[0].children]
      const terms = ["product", "produk", "製品"]
      const elProduct = navbarChild.filter(el => {
        const eltext = el.innerText.toLowerCase();
        return terms.some(term => eltext.includes(term))
      })
      if (elProduct[0].children[1]) {
        const elNavbar = jQuery('.navbar')
        const navbarCollapse = jQuery('.navbar-collapse')
        if (navbarCollapse[0]) {
          const breadcrumbProductMobile = jQuery('.breadcrumb-product-mobile');
          if (breadcrumbProductMobile[0]) {
            const navbarMenu = navbarCollapse[0].children[0];

            // set product dropdown styling
            const itemMenus = [...navbarMenu.children]
            const terms = ["product", "produk", "製品"]
            const allProducts = []

            let dropdownProductMobile = ""
            dropdownProductMobile = document.createElement('div');
            dropdownProductMobile.classList.add("product-menu-mobile")
            dropdownProductMobile.style.display = 'none'

            let backButtonMobile = jQuery('.product-menu-back');

            itemMenus.forEach((el) => {
              const eltext = el.children[0].innerText.toLowerCase();
              let isProduct = terms.some(term => eltext.includes(term))
              if (isProduct && el.children[1]) {
                setTimeout(() => {
                  let elChildren = [...el.children]
                  let objElement = [...elChildren[1].children];
                  objElement.forEach(item => {
                    let itemElement = [...item.children]
                    let navItem = [...itemElement[1].children]
                    navItem.forEach(el => {
                      allProducts.push(el)
                    })
                  })
                }, 300);
              }
            })

            backButtonMobile[0].classList.remove('d-none');
            dropdownProductMobile.appendChild(backButtonMobile[0]);

            backButtonMobile[0].addEventListener("click", () => {
              dropdownProductMobile.style.display = 'none'
            })

            elNavbar[0].appendChild(dropdownProductMobile);

            let breadcrumbMobileEL = [...breadcrumbProductMobile];
            breadcrumbMobileEL.forEach(bread => {
              bread.addEventListener("click", function () {
                let dropdownWrapper = ""
                dropdownWrapper = document.createElement('ul');
                dropdownWrapper.classList.add("items")

                if (dropdownProductMobile.children.length > 1) {
                  dropdownProductMobile.removeChild(dropdownProductMobile.children[1])
                }

                allProducts.forEach(el => {
                  if (el) {
                    dropdownWrapper.appendChild(el);
                    dropdownProductMobile.appendChild(dropdownWrapper);
                  }
                })

                dropdownProductMobile.style.display = 'block'
              });
            });
          }
        }
      }
    }
  },

  newsSlider: function () {
    jQuery('.news-slider').slick({
      arrows: 1,
      dots: 1,
      cssEase: "ease-out",
      speed: 300,
      autoplaySpeed: 2000,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
    })

    jQuery('.btn-slick-previous').on('click', function () {
      jQuery('.news-slider').slick('slickPrev')
    })

    jQuery('.btn-slick-next').on('click', function () {
      jQuery('.news-slider').slick('slickNext')
    })
  },

  newsSliderTablet: function () {
    jQuery('.news-slider-tablet').slick({
      arrows: 1,
      dots: 1,
      cssEase: "ease-out",
      speed: 300,
      autoplaySpeed: 2000,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
    })

    jQuery('.btn-slick-previous-tablet').on('click', function () {
      jQuery('.news-slider-tablet').slick('slickPrev')
    })

    jQuery('.btn-slick-next-tablet').on('click', function () {
      jQuery('.news-slider-tablet').slick('slickNext')
    })
  },

  companyMore: function () {
    const categoryMore = jQuery('.category-more-view')
    if (categoryMore[0]) {
      let btnEl = [...categoryMore];
      let currentActiveCategory = null;
      let currentActiveMode = null;
      let currentActiveRow = null;
      let currentActiveBtn = null;
      btnEl.forEach((item, index) => {
        item.addEventListener("click", function () {
          item.click();
          const seeMoreBrand = jQuery('.' + item.getAttribute('data-target-brand')) 
          const categoryActive = seeMoreBrand[0].getAttribute("data-category")
          const seeMoreMode = item.getAttribute('data-mode')
          const rowActive = seeMoreBrand[0].getAttribute("data-row")
          const targetElement = item.parentNode.children[3].cloneNode(true)
          if(seeMoreBrand[0].children.length > 0){
            if(currentActiveCategory == categoryActive && currentActiveRow == rowActive && currentActiveBtn == index){
              categoryMore[currentActiveBtn].children[1].style.transform = "rotate(0)"
              seeMoreBrand[0].replaceChildren();
              currentActiveCategory = null;
              currentActiveMode = null;
              currentActiveRow = null;
              currentActiveBtn = null;
            } else {
              const currentSeeMoreBrand = jQuery('.' + item.getAttribute('data-target-brand'))
              currentSeeMoreBrand[0].replaceChildren();
              categoryMore[currentActiveBtn].children[1].style.transform = "rotate(0)"
              categoryMore[index].children[1].style.transform = "rotate(180deg)"
              seeMoreBrand[0].replaceChildren();
              seeMoreBrand[0].appendChild(targetElement)
              currentActiveCategory = categoryActive;
              currentActiveMode = seeMoreMode;
              currentActiveRow = rowActive;
              currentActiveBtn = index;
            }
          } else {
            if (currentActiveRow == rowActive){
              categoryMore[index].children[1].style.transform = "rotate(180deg)"
              targetElement.style.backgroundColor = '#fff';
              seeMoreBrand[0].appendChild(targetElement)
              currentActiveCategory = categoryActive;
              currentActiveMode = seeMoreMode;
              currentActiveRow = rowActive;
              currentActiveBtn = index;
            } else {
              if(currentActiveCategory && currentActiveRow && currentActiveBtn !== null){
                const currentSeeMoreBrand = jQuery('.' + 'load-brand-list-' + currentActiveMode + '-' + currentActiveCategory + '-' + currentActiveRow)
                currentSeeMoreBrand[0].replaceChildren();
                
                categoryMore[currentActiveBtn].children[1].style.transform = "rotate(0)"
                categoryMore[index].children[1].style.transform = "rotate(180deg)"
                targetElement.style.backgroundColor = '#fff';
                seeMoreBrand[0].appendChild(targetElement)
                currentActiveCategory = categoryActive;
                currentActiveMode = seeMoreMode;
                currentActiveRow = rowActive;
                currentActiveBtn = index;
              } else {
                categoryMore[index].children[1].style.transform = "rotate(180deg)"
                targetElement.style.backgroundColor = '#fff';
                seeMoreBrand[0].appendChild(targetElement)
                currentActiveCategory = categoryActive;
                currentActiveMode = seeMoreMode;
                currentActiveRow = rowActive;
                currentActiveBtn = index;
              }
            }
          }
        })
      })
    }

    const categoryAnimation = jQuery('.processing-item');
    if (categoryAnimation[0]) {
      let btnProcessing = [...categoryAnimation];
      btnProcessing.forEach((item) => {
        item.addEventListener("click", function () {
          item.children[0].children[0].children[1].children[2].click()
        })
      })
    }
  },

  initStepsContact: function () {
    // menus step indicator
    const elMenus = jQuery('.menu')
    const elMenusActive = jQuery('.menu-active')

    // add contact form 7
    const allForm = jQuery('.wpcf7')
    const formCompany7 = jQuery('.form-company-7')
    const formName7 = jQuery('.form-name-7')
    const formPhone7 = jQuery('.form-phonenumber-7')
    const formEmail7 = jQuery('.form-email-7')
    const formTextarea7 = jQuery('.form-textarea-7')
    const formCheck7 = jQuery('.form-check-input-7')
    const formSubmit7 = jQuery('.form-submit-7')

    const btnPhone = jQuery('.input-group-prepend')
    const phoneCountry = jQuery('.phone-country')
    const formPhone = jQuery('.form-phonenumber')
    const elPhoneToggle = jQuery('.phone-toggle')

    const formPhoneGroup = jQuery('.form-phone-group');
    const regExp = /^[0-9]+$/;
    let isNumbers;
    // set phone
    if (formPhone7[0]) {
      formPhone7[0].style.display = 'none';
      const formPhoneParrent = formPhone7[0].parentNode;
      formPhoneParrent.appendChild(formPhoneGroup[0]);

      formPhone[0].addEventListener("input", () => {
        formPhone7[0].value = elPhoneToggle[0].innerHTML + formPhone[0].value
        isNumbers = formPhone[0].value.match(regExp);
        if ((isNumbers == null && formPhone7[0].parentNode.parentNode.children.length == 2) && formPhone[0].value.length > 0) {
          const validatePhone = document.createElement("span");
          validatePhone.classList.add('wpcf7-not-valid-tip')
          validatePhone.setAttribute('aria-hidden', 'true')
          validatePhone.innerHTML = "Only number is allowed."
          formPhone7[0].parentNode.parentNode.appendChild(validatePhone);
        } else {
          if (!formPhone[0].value && formPhone7[0].parentNode.parentNode.children.length == 2) {
            const validatePhone = document.createElement("span");
            validatePhone.classList.add('wpcf7-not-valid-tip')
            validatePhone.setAttribute('aria-hidden', 'true')
            validatePhone.innerHTML = "Please fill out this field."
            formPhone7[0].parentNode.parentNode.appendChild(validatePhone);
          } else {
            if (formPhone[0].value && formPhone7[0].parentNode.parentNode.children.length == 3 && isNumbers != null) {
              formPhone7[0].parentNode.parentNode.removeChild(formPhone7[0].parentNode.parentNode.lastChild)
            }

            if (formPhone7[0].parentNode.parentNode.children.length == 3 && isNumbers == null) {
              formPhone7[0].parentNode.parentNode.removeChild(formPhone7[0].parentNode.parentNode.lastChild)
              const validatePhone = document.createElement("span");
              validatePhone.classList.add('wpcf7-not-valid-tip')
              validatePhone.setAttribute('aria-hidden', 'true')
              validatePhone.innerHTML = "Only number is allowed."
              formPhone7[0].parentNode.parentNode.appendChild(validatePhone);
            }
          }
        }
      });
    }

    // listen to checked box
    if (formCheck7[0]) {
      formCheck7[0].children[0].children[0].addEventListener("change", () => {
        formPhone7[0].value = elPhoneToggle[0].innerHTML + formPhone[0].value
        if (!formPhone[0].value && formPhone7[0].parentNode.parentNode.children.length == 2) {
          const validatePhone = document.createElement("span");
          validatePhone.classList.add('wpcf7-not-valid-tip')
          validatePhone.setAttribute('aria-hidden', 'true')
          validatePhone.innerHTML = "Please fill out this field."
          formPhone7[0].parentNode.parentNode.appendChild(validatePhone);
        } else {
          if (formPhone[0].value && formPhone7[0].parentNode.parentNode.children.length == 3 && isNumbers != null) {
            formPhone7[0].parentNode.parentNode.removeChild(formPhone7[0].parentNode.parentNode.lastChild)
          }
        }
      })
    }

    // set textarea
    if (formTextarea7[0]) {
      formTextarea7[0].style.height = '125px';
    }

    // set product field
    const formproduct7 = jQuery('.form-product-7')
    const fieldValueProduct = jQuery('.product-request-field')

    if (formproduct7[0] && fieldValueProduct[0].value) {
      formproduct7[0].value = fieldValueProduct[0].value
      formproduct7[0].disabled = true
    }

    // set button
    const formContactUs = jQuery('.form-contact-us')
    const btnSubmit = jQuery('.btn-submit')
    const btnConfirm = jQuery('.btn-confirm')
    const btnEdit = jQuery('.btn-edit')
    const btnFinish = jQuery('.btn-finish')

    if (formSubmit7[0]) {
      if(btnSubmit[0]){
        btnSubmit[0].innerHTML = formSubmit7[0].value
        formSubmit7[0].style.display = 'none';
        const formSubmitParrent = formSubmit7[0].parentNode;
        formSubmitParrent.appendChild(formContactUs[0]);
      }
    }

    if (elMenus.length > 0) {
      elMenus[0].classList.add("active")
      elMenus[0].children[1].classList.add("inter-bold")
    }

    btnSubmit.on('click', function () {
      if (
        !formCompany7[0].value ||
        !formName7[0].value ||
        !formPhone7[0].value ||
        isNumbers == null ||
        !formEmail7[0].value ||
        !formCheck7[0].children[0].children[0].checked
      ) {
        if (formCheck7[0].parentNode.children.length <= 1) {
          // show error notification under check box
          const validateError = document.createElement("span");
          validateError.classList.add('wpcf7-not-valid-tip')
          validateError.setAttribute('aria-hidden', 'true')
          validateError.innerHTML = "Please fill out all required field."
          formCheck7[0].parentNode.appendChild(validateError);
        }
      } else {
        if (formCheck7[0].parentNode.children.length > 1) {
          formCheck7[0].parentNode.removeChild(formCheck7[0].parentNode.children[1])
        }
        formCompany7[0].disabled = true
        formName7[0].disabled = true
        formPhone7[0].disabled = true
        formPhone7[0].value = elPhoneToggle[0].innerHTML + formPhone[0].value

        formPhone[0].disabled = true
        btnPhone[0].parentNode.parentNode.classList.add("bg-disable")
        phoneCountry[0].classList.add("display-none")
        if (formTextarea7[0]) {
          formTextarea7[0].disabled = true
        }
        formEmail7[0].disabled = true
        formCheck7[0].classList.add("display-none")

        // button hidden
        btnSubmit[0].classList.add("display-none")
        btnConfirm[0].classList.remove("display-none")
        btnEdit[0].classList.remove("display-none")

        if (elMenus[0]) {
          elMenus[0].classList.remove("active")
          elMenus[0].classList.add("display-none")
        }
        if (elMenusActive[0]) {
          elMenusActive[0].classList.remove("display-none")
        }
        if (elMenus[1]) {
          elMenus[1].classList.add("active")
          elMenus[1].children[1].classList.add("inter-bold")
        }
      }
    })

    btnEdit.on('click', function () {
      formCompany7[0].disabled = false
      formName7[0].disabled = false
      formPhone7[0].disabled = false
      formEmail7[0].disabled = false
      formCheck7[0].classList.remove("display-none")
      if (formTextarea7[0]) {
        formTextarea7[0].disabled = false
      }
      formPhone[0].disabled = false
      btnPhone[0].parentNode.parentNode.classList.remove("bg-disable")
      phoneCountry[0].classList.remove("display-none")

      // button hidden
      btnSubmit[0].classList.remove("display-none")
      btnConfirm[0].classList.add("display-none")
      btnEdit[0].classList.add("display-none")

      elMenus[0].classList.add("active")
      elMenus[0].classList.remove("display-none")
      elMenusActive[0].classList.add("display-none")
      elMenus[1].classList.remove("active")
      elMenus[1].children[1].classList.remove("inter-bold")
    })

    // form thank you
    const formThankYou = jQuery('.form-thank-you')

    btnConfirm.on('click', function () {
      if (formproduct7[0]) {
        formproduct7[0].disabled = false
      }
      formThankYou[0].classList.remove("display-none")
      formCompany7[0].disabled = false
      formName7[0].disabled = false
      formPhone7[0].disabled = false
      formEmail7[0].disabled = false
      if (formTextarea7[0]) {
        formTextarea7[0].disabled = false
      }
      allForm[0].classList.add("display-none")
      formSubmit7[0].click()

      if (elMenus[1]) {
        elMenus[1].classList.add("display-none");
      }
      if (elMenusActive[1]) {
        elMenusActive[1].classList.remove("display-none")
      }
      if (elMenus[2]) {
        elMenus[2].classList.add("display-none")
      }
      elMenusActive[2].classList.remove("display-none")
    })

    btnFinish.on('click', function () {
      window.location.reload();
    })
  },

  initCountryPhones: function () {
    const elPhoneToggle = jQuery('.phone-toggle')
    const initCode = codes.find(code => code.country === "Indonesia")
    if (elPhoneToggle[0] && initCode.code) {
      elPhoneToggle[0].innerHTML = '+' + initCode.code;
    }
  },

  countryPhones: function () {
    const elPhoneCode = jQuery('.phone-country')
    codes.forEach(code => {
      const option = document.createElement("div");
      option.classList.add('dropdown-item')
      option.innerHTML = code.country + ' (+' + code.code + ')';
      if (elPhoneCode[0]) {
        elPhoneCode[0].appendChild(option)
      }
    })

    const elPhoneToggle = jQuery('.phone-toggle')
    elPhoneCode.on('click', function (e) {
      const targetCode = e.target.innerHTML;
      const lastIndex = targetCode.lastIndexOf(" ");
      const stringSubs = targetCode.substring(0, lastIndex);
      const clickedCode = codes.find(code => code.country === stringSubs)
      if (elPhoneToggle[0] && clickedCode.code) {
        elPhoneToggle[0].innerHTML = '+' + clickedCode.code;
      }
    })
  },

  newsListUnderlineEffect: function () {
    var cardImages = document.querySelectorAll('.card-image-list');
    var titles = document.querySelectorAll('.card-content');


    cardImages.forEach((cardImage, index) => {
      var title = titles[index];

      cardImage.addEventListener('mouseover', function () {
        title.style.textDecoration = 'underline';
      });

      cardImage.addEventListener('mouseout', function () {
        title.style.textDecoration = 'none';
      });
    });

    let cardNews = jQuery('.news-item');
    let newsTitle = jQuery('.news-title');

    if (cardNews[0]) {
      let cardEl = [...cardNews];
      cardEl.forEach((card, index) => {
        card.addEventListener("mouseover", function () {
          if (card.children[0].children[0].children[0].nodeName.toLowerCase() === 'img') {
            card.children[0].children[0].children[0].style.transition = '0.5s ease-in-out';
            card.children[0].children[0].children[0].style.scale = '1.25';
          }
          if (newsTitle[0]) {
            newsTitle[index].style.transition = '0.5s ease-in-out';
            newsTitle[index].style.textDecoration = 'underline';
          }
        });
        card.addEventListener("mouseout", function () {
          if (card.children[0].children[0].children[0].nodeName.toLowerCase() === 'img') {
            card.children[0].children[0].children[0].style.transition = '0.5s ease-in-out';
            card.children[0].children[0].children[0].style.scale = '1';
          }
          if (newsTitle[0]) {
            newsTitle[index].style.transition = '0.5s ease-in-out';
            newsTitle[index].style.textDecoration = 'none';
          }
        });
      })
    }

  },

  newsListLoadMore: function () {
    var cards = document.querySelectorAll('.card-news-col');
    var loadMoreButton = document.getElementById('load-more');

    if (cards[0]) {
      for (var i = 6; i < cards.length; i++) {
        cards[i].style.display = 'none';
      }

      if (cards.length > 6) {
        loadMoreButton.style.display = 'block';
        loadMoreButton.addEventListener('click', function () {
          for (var i = 6; i < cards.length; i++) {
            cards[i].style.display = 'block';
            loadMoreButton.style.display = 'none';
          }
        });
      } else {
        if (loadMoreButton) {
          loadMoreButton.style.display = 'none';
        }
      }
    }
  },
  corporateLoadMore: function () {
    var screenWidth = window.innerWidth;
    var cards = document.querySelectorAll('.mobile-corporation');
    var loadMoreButton = document.getElementById('corporation-load-more');
    if (screenWidth <= 1020) {
      if (cards[0]) {
        for (var i = 3; i < cards.length; i++) {
          cards[i].style.display = 'none';
        }

        if (cards.length > 3) {
          loadMoreButton.style.display = 'block';
          loadMoreButton.addEventListener('click', function () {
            for (var i = 3; i < cards.length; i++) {
              cards[i].style.display = 'block';
              loadMoreButton.classList.remove('d-block')
              loadMoreButton.style.display = 'none'; // Mengubah tampilan tombol "load more" menjadi tidak terlihat setelah diklik.
            }
          });
        } else {
          loadMoreButton.classList.remove('d-block')
          loadMoreButton.style.display = 'none'; // Mengubah tampilan tombol "load more" menjadi tidak terlihat setelah diklik.
        }
      }
    }
  },

  postCarousel: function () {
    function adjustSlidesToShow() {
      if (window.matchMedia("(max-width: 990px)").matches) {
        return 3;
      } else {
        return 3;
      }
    }

    jQuery('.news-post').slick({
      arrows: true,
      dots: true,
      cssEase: "ease-out",
      speed: 300,
      autoplaySpeed: 2000,
      infinite: true,
      slidesToShow: adjustSlidesToShow(),
      slidesToScroll: adjustSlidesToShow(),
    });

    jQuery(window).on('resize', function () {
      jQuery('.news-post').slick('slickSetOption', {
        slidesToShow: adjustSlidesToShow(),
        slidesToScroll: adjustSlidesToShow(),
      }, true);
    });

    jQuery('.left-arrow').on('click', function () {
      jQuery('.news-post').slick('slickPrev');
    });

    jQuery('.right-arrow').on('click', function () {
      jQuery('.news-post').slick('slickNext');
    });
  },

  filterCateogry: function () {
    jQuery(document).ready(function ($) {
      $('.category-choice').click(function (e) {
        var categoryName = $(this).text();
        $('#filter-category').text(categoryName);
        e.preventDefault();
        var categoryId = $(this).data('category-id');
        $.ajax({
          url: '/wp-admin/admin-ajax.php',
          type: 'POST',
          data: {
            action: 'filter_posts_by_category',
            category_id: categoryId
          },
          success: function (response) {
            // Update your page with the filtered content.
            $('#content').html(response);
          }
        });
      });
    });
  },

  navbarCollapseDropdown: function () {
    // get all incorporating navbar dropdown elements
    const btnToggler = jQuery('.navbar-toggler')
    const iconDeactive = jQuery('.navbar-icon-deactive')
    const iconactive = jQuery('.navbar-icon-active')
    const elNavbar = jQuery('.navbar')
    const navbarCollapse = jQuery('.navbar-collapse')
    if (navbarCollapse[0]) {
      const navbarMenu = navbarCollapse[0].children[0];
      const langIconMobile = jQuery('.lang-icon-mobile')
      const dropdownMenuLang = jQuery('.dropdown-menu-lang')

      // used for running initial appendchild
      let collapseCount = 0
      let isCollapse = true
      navbarMenu.classList.add("navbar-mobile-collapse")
      navbarMenu.classList.add("deactive-collapse")
      iconDeactive[0].style.display = "block"
      iconactive[0].style.display = "none"

      // set product dropdown styling
      const itemMenus = [...navbarMenu.children]
      const terms = ["product", "produk", "製品"]
      const allProducts = []

      let dropdownProduct = ""
      dropdownProduct = document.createElement('div');
      dropdownProduct.classList.add("product-menu")
      dropdownProduct.style.display = 'none'

      const backButton = jQuery('.product-menu-back');

      itemMenus.forEach((el) => {
        const eltext = el.children[0].innerText.toLowerCase();
        let isProduct = terms.some(term => eltext.includes(term))
        if (isProduct && el.children[1]) {
          setTimeout(() => {
            let elChildren = [...el.children]
            let objElement = [...elChildren[1].children];
            objElement.forEach(item => {
              let itemElement = [...item.children]
              let navItem = [...itemElement[1].children]
              navItem.forEach(el => {
                allProducts.push(el)
              })
            })
          }, 300);
          el.classList.add("product-toggle")
        }
      })

      const productToggle = jQuery('.product-toggle')
      const arrowProductToggle = jQuery('.arrow-product-toggle')
      if (productToggle[0]) {
        productToggle[0].appendChild(arrowProductToggle[0]);
      }

      let productToggleShow = false
      productToggle.on('click', function () {
        if (productToggleShow) {
          dropdownProduct.style.display = 'none'
        } else {
          let dropdownWrapper = ""
          dropdownWrapper = document.createElement('ul');
          dropdownWrapper.classList.add("items")

          if (dropdownProduct.children.length > 1) {
            dropdownProduct.removeChild(dropdownProduct.children[1])
          }
          allProducts.forEach(el => {
            if (el) {
              dropdownWrapper.appendChild(el);
              dropdownProduct.appendChild(dropdownWrapper);
            }
          })

          dropdownProduct.style.display = 'block'
        }
      })

      btnToggler.on('click', function () {
        // initial appendchild
        if (collapseCount == 0) {
          let li = document.createElement('li');
          li.classList.add("nav-item")
          li.classList.add("nav-item-lang")
          li.appendChild(langIconMobile[0])
          li.appendChild(dropdownMenuLang[0])
          navbarMenu.appendChild(li);
          btnToggler[0].parentNode.appendChild(navbarMenu);

          backButton[0].classList.remove('d-none');
          dropdownProduct.appendChild(backButton[0]);
          elNavbar[0].appendChild(dropdownProduct);

          backButton[0].addEventListener("click", () => {
            dropdownProduct.style.display = 'none'
          })
        }

        // set status collapse button
        isCollapse = !isCollapse

        if (isCollapse === false) {
          elNavbar[0].classList.add("navbar-fixed")
          navbarMenu.classList.remove("deactive-collapse")
          navbarMenu.classList.add("active-collapse")
          iconDeactive[0].style.display = "none"
          iconactive[0].style.display = "block"
        }

        if (isCollapse === true) {
          elNavbar[0].classList.remove("navbar-fixed")
          navbarMenu.classList.remove("active-collapse")
          navbarMenu.classList.add("deactive-collapse")
          iconDeactive[0].style.display = "block"
          iconactive[0].style.display = "none"
        }

        // set counting for clicking collapse button
        collapseCount++
      })
    }
  },

  backToTop: function () {
    const btnBack = jQuery('.back-to-top')
    if (btnBack[0]) {
      btnBack.on('click', function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
    }
  },

  GoogleSearchBoxPlaceHolder: function () {
    (function () {
      var cx = '!!!!!!!!!!!!!!!!!!!';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    })();
    window.onload = function () {
      var searchBox = document.getElementById('gsc-i-id1');
      if (searchBox) {
        searchBox.placeholder = 'SEARCH';
      }
    };
  },

  productDetailPreview: function () {
    const btnPrev = jQuery('.btn-product-detail-prev')
    const btnNext = jQuery('.btn-product-detail-next')
    const detailCover = jQuery('.product-detail-cover')
    const slideImages = jQuery('.product-detail-images')

    let currentSlidePosition = 0
    let slideLength = 0;
    let slideItems;

    // initial page load
    if (slideImages[0]) {
      slideItems = [...slideImages[0].children];
      slideLength = slideItems.length;

      if (detailCover[0] && slideLength !== 0) {
        detailCover[0].appendChild(slideItems[currentSlidePosition]);
      }
    }

    if (btnPrev[0] && slideLength !== 0) {
      btnPrev.on('click', function () {
        slideImages[0].prepend(detailCover[0].children[0]);
        slideItems = [...slideImages[0].children];
        detailCover[0].appendChild(slideItems[slideLength - 1]);
      })
    }

    if (btnNext[0] && slideLength !== 0) {
      btnNext.on('click', function () {
        slideImages[0].appendChild(detailCover[0].children[0]);
        slideItems = [...slideImages[0].children];
        detailCover[0].appendChild(slideItems[0]);
      })
    }
  },

  productDetailPreviewMobile: function () {
    const btnPrev = jQuery('.btn-product-detail-prev-mobile')
    const btnNext = jQuery('.btn-product-detail-next-mobile')
    const detailCover = jQuery('.product-detail-cover-mobile')
    const slideImages = jQuery('.product-detail-images-mobile')

    let currentSlidePosition = 0
    let slideLength = 0;
    let slideItems;

    // initial page load
    if (slideImages[0]) {
      slideItems = [...slideImages[0].children];
      slideLength = slideItems.length;

      if (detailCover[0] && slideLength !== 0) {
        detailCover[0].appendChild(slideItems[currentSlidePosition]);
      }
    }

    if (btnPrev[0] && slideLength !== 0) {
      btnPrev.on('click', function () {
        slideImages[0].prepend(detailCover[0].children[0]);
        slideItems = [...slideImages[0].children];
        detailCover[0].appendChild(slideItems[slideLength - 1]);
      })
    }

    if (btnNext[0] && slideLength !== 0) {
      btnNext.on('click', function () {
        slideImages[0].appendChild(detailCover[0].children[0]);
        slideItems = [...slideImages[0].children];
        detailCover[0].appendChild(slideItems[0]);
      })
    }
  },

  productDetailTableRow: function () {
    const tableProduct = jQuery('.table-about-product');
    const tableProductMobile = jQuery('.table-about-product-mobile');

    if (tableProduct[0]) {
      const tableEl = tableProduct[0].children[0]

      if (tableEl) {
        const tableBody = tableEl.children[0]
        const thEl = [...tableBody.children]
        thEl.forEach((th, index) => {
          if (index % 2 !== 0) {
            th.classList.add("stripped")
          }
        })
      }
    }

    if (tableProductMobile[0]) {
      const tableElMobile = tableProductMobile[0].children[0]

      if (tableElMobile) {
        const tableBodyMobile = tableElMobile.children[0]
        const thElMobile = [...tableBodyMobile.children]
        thElMobile.forEach((th, index) => {
          if (index % 2 !== 0) {
            th.classList.add("stripped")
          }
        })
      }
    }
  },

  productDetailRelated: function () {
    const productRelated = jQuery('.product-related-item');
    if (productRelated[0]) {
      let relatedEl = [...productRelated]
      relatedEl.forEach(item => {
        item.addEventListener("mouseover", function () {
          item.children[0].children[0].style.opacity = '1';
          item.children[0].children[1].style.opacity = '1';
          item.children[0].children[1].style.color = '#EC1C24';
          item.children[0].children[2].style.opacity = '1';
          item.children[0].children[2].style.color = '#EC1C24';
        });

        item.addEventListener("mouseout", function () {
          item.children[0].children[0].style.opacity = '0.75';
          item.children[0].children[1].style.opacity = '0.75';
          item.children[0].children[1].style.color = '#3C485E';
          item.children[0].children[2].style.opacity = '0.75';
          item.children[0].children[2].style.color = '#3C485E';
        });
      })
    }
  },

  btnSwitchingHover: function () {
    const btnSwitching = jQuery('.btn-switching-hover');
    if (btnSwitching[0]) {
      let arrowEl = [...btnSwitching];
      arrowEl.forEach(item => {
        item.children[1].style.display = 'block';
        item.children[2].style.display = 'none';

        item.addEventListener("mouseover", function () {
          item.children[1].style.display = 'none';
          item.children[2].style.display = 'block';
        });

        item.addEventListener("mouseout", function () {
          item.children[1].style.display = 'block';
          item.children[2].style.display = 'none';
        });
      })
    }
  },

  searchMobileView: function () {
    const btnNavbarSearch = jQuery('.navbar-search-mobile');
    const btnNavbarSearchClose = jQuery('.navbar-search-mobile-close');
    const formSearch = jQuery('.navbar-search-form-mobile');
    const formSearchBackground = jQuery('.navbar-search-mobile-background');
    let isActive = false;

    if (btnNavbarSearch[0]) {
      btnNavbarSearch.on('click', function () {
        if (formSearch[0]) {
          if (!isActive) {
            formSearch[0].style.top = '155px';
            formSearch[0].style.opacity = '1';
            formSearchBackground[0].style.display = 'block';
            formSearchBackground[0].style.zIndex = '9999';
            isActive = true;
          } else {
            formSearch[0].style.top = '-50px';
            formSearch[0].style.opacity = '0';
            formSearchBackground[0].style.display = 'none';
            formSearchBackground[0].style.zIndex = '-9999';
            isActive = false;
          }
        }
      })

      if (btnNavbarSearchClose[0]) {
        btnNavbarSearchClose.on('click', function () {
          formSearch[0].style.top = '-50px';
          formSearch[0].style.opacity = '0';
          formSearchBackground[0].style.opacity = '0';
          formSearchBackground[0].style.zIndex = '-9999';
          isActive = false;
        })
      }
    }
  },

  dropdownOurBusiness: function () {
    const dropdownOurBusiness = jQuery('.dropdown-our-business');
    if (dropdownOurBusiness[0]) {
      let dropdownEl = [...dropdownOurBusiness];
      dropdownEl.forEach(item => {
        let isActive = false;
        item.addEventListener("click", function () {
          if (!isActive) {
            item.children[2].style.transition = '0.5s ease-in-out';
            item.children[2].style.transform = 'rotate(180deg)';
            isActive = true;
          } else {
            item.children[2].style.transition = '0.5s ease-in-out';
            item.children[2].style.transform = 'rotate(0deg)';
            isActive = false;
          }
        });
        item.addEventListener("focusout", function () {
          item.children[2].style.transition = '0.5s ease-in-out';
          item.children[2].style.transform = 'rotate(0deg)';
          isActive = false;
        });
      })
    }
  },

  productListContent: function () {
    const productList = jQuery('.product-list-content');
    if (productList[0]) {
      let productListEl = [...productList];
      productListEl.forEach(item => {
        item.addEventListener("mouseover", function () {
          item.children[0].children[0].style.transition = '0.5s ease-in-out';
          item.children[0].children[0].style.scale = '0.85';
          item.children[0].children[1].style.transition = '0.5s ease-in-out';
          item.children[0].children[1].style.color = '#EC1C24';
          item.children[1].style.backgroundColor = '#3C485E';
          item.children[1].style.color = '#fff';
          item.children[1].style.margin = '1rem 0';
        });
        item.addEventListener("mouseout", function () {
          item.children[0].children[0].style.transition = '0.5s ease-in-out';
          item.children[0].children[0].style.scale = '1';
          item.children[0].children[1].style.transition = '0.5s ease-in-out';
          item.children[0].children[1].style.color = '#3C485E';
          item.children[1].style.backgroundColor = 'transparent';
          item.children[1].style.color = '#9A9A9A';
          item.children[1].style.margin = '0';
        });
      })
    }

    const productListManufacture = jQuery('.product-list-content-manufacture');
    if (productListManufacture[0]) {
      let productListEl = [...productListManufacture];
      productListEl.forEach(item => {
        item.addEventListener("mouseover", function () {
          item.children[0].children[1].style.transition = '0.5s ease-in-out';
          item.children[0].children[1].style.textDecoration = 'underline';
          item.children[1].style.backgroundColor = '#3C485E';
          item.children[1].style.color = '#fff';
        });
        item.addEventListener("mouseout", function () {
          item.children[0].children[1].style.transition = '0.5s ease-in-out';
          item.children[0].children[1].style.textDecoration = 'none';
          item.children[1].style.backgroundColor = 'transparent';
          item.children[1].style.color = '#9A9A9A';
        });
      })
    }
  },
  searchGoogleNavbar: function () {
    function redirectToGCSESearch(keyword) {
      function getPathSegment() {
        var path = window.location.pathname;
        var pathSegments = path.split('/');
        return pathSegments[1];
      }

      var domain = document.querySelector('meta[name="custom-metadata"]');
      var baseUrls = {
        "id": `${domain}/id/search-enhanced-by-google-2/#gsc.tab=0&gsc.sort=&gsc.q=`,
        "jp": `${domain}/jp/search-enhanced-by-google-1/#gsc.tab=0&gsc.sort=&gsc.q=`,
        "default": `${domain}/search-enhanced-by-google/#gsc.tab=0&gsc.sort=&gsc.q=`
      };

      var language = getPathSegment();
      var searchURL = baseUrls[language] ? baseUrls[language] + encodeURIComponent(keyword) : baseUrls["default"] + encodeURIComponent(keyword);

      window.location.href = searchURL;
    }

    document.getElementById('searchInputDesktop').addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        var keyword = event.target.value;
        redirectToGCSESearch(keyword);
      }
    });

    document.getElementById('searchInput').addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        var keyword = event.target.value;
        redirectToGCSESearch(keyword);
      }
    });
  },
  searchToogleDesktop: function () {
    const searchButton = document.querySelector('#navbar-search-desktop');
    const mainNav = document.getElementById('main-nav');
    const searchFormDesktop = document.querySelector('.navbar-search-form-desktop');
    const searchInputDesktop = document.getElementById('searchInputDesktop');
    const closeSearchButton = document.querySelector('.navbar-search-desktop-close');
    searchButton.addEventListener('click', function () {
      mainNav.classList.remove('show');
      setTimeout(() => {
        mainNav.style.display = 'none';
        searchFormDesktop.classList.remove('hide');
        searchFormDesktop.classList.add('show');
        searchInputDesktop.focus();
      }, 500); // match this duration with the CSS transition time
    });

    closeSearchButton.addEventListener('click', function () {
      searchFormDesktop.classList.remove('show');
      setTimeout(() => {
        searchFormDesktop.classList.add('hide');
        mainNav.style.display = 'flex';
        mainNav.classList.add('show');
      }, 500); // match this duration with the CSS transition time
    });

  },

  btnScrollToBottom: function () {
    const btnToBottom = jQuery('.carousel-bottom');
    btnToBottom.on('click', function () {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    });
  },

  productListLoadMore: function () {
    const btnLoadMore = jQuery('.btn-load-more-product-list');
    const btnLoadMoreMobile = jQuery('.btn-load-more-product-list-mobile');
    const cardProductItem = jQuery('.sub-product-list');

    if (cardProductItem.length > 8) {
      cardProductItem.slice(8).addClass('product-hidden');
      btnLoadMore.show();
      btnLoadMoreMobile.show();
    } else {
      btnLoadMore.removeClass('d-xl-flex');
      btnLoadMoreMobile.removeClass('d-flex');
    }

    if (btnLoadMore[0]) {
      if (cardProductItem[0]) {
        let cardEl = [...cardProductItem];
        btnLoadMore.on('click', function () {
          cardEl.forEach(item => {
            item.style.transition = '0.5s ease-in-out';
            item.classList.remove('product-hidden');
          });
          btnLoadMore[0].style.transition = '0.5s ease-in-out';
          btnLoadMore[0].style.opacity = 0;
        });

        btnLoadMoreMobile.on('click', function () {
          cardEl.forEach(item => {
            item.style.transition = '0.5s ease-in-out';
            item.classList.remove('product-hidden');
          });
          btnLoadMoreMobile[0].style.transition = '0.5s ease-in-out';
          btnLoadMoreMobile[0].style.opacity = 0;
        });
      }
    }
  },

  changeLanguage: function () {
    const languageSwitcher = jQuery('.language-switcher');
    languageSwitcher.on('change', function () {
      window.location.href = languageSwitcher[0].value;
    });
  },

  categoryAnimation: function () {
    const categoryAnimation = jQuery('.processing-item');
    if (categoryAnimation[0]) {
      let categoryEl = [...categoryAnimation];
      categoryEl.forEach(item => {
        const itemBody = item.children[0].children[0].children[1]
        item.addEventListener("mouseover", function () {         
          switch (itemBody.children[0].classList[0]) {
            case "red-line":
              itemBody.children[1].style.backgroundColor = 'red';
              break;
            case "green-line":
              itemBody.children[1].style.backgroundColor = '#00aa4e';
              break;
            case "blue-line":
              itemBody.children[1].style.backgroundColor = '#00b8f1';
              break;
          }
          itemBody.children[1].style.transition = '0.5s ease-in-out';
          itemBody.children[1].children[0].style.color = 'white';
        });
        item.addEventListener("mouseout", function () {
          itemBody.children[1].style.backgroundColor = '#EAECF1';
          itemBody.children[1].style.transition = '0.5s ease-in-out';
          itemBody.children[1].children[0].style.color = '#3C485E';
        });
      })
    }
  },
}

jQuery(document).ready(function () {
  general.init();
});

